const customInput = prompt => {
  customPrint(prompt);
  const input = document.getElementById('console-input');
  return new Promise(resolve => {
    const onEnter = event => {
      if (event.key === 'Enter') {
        const userInput = input.value;
        input.value = '';
        input.removeEventListener('keyup', onEnter);
        resolve(userInput);
      }
    };

    input.addEventListener('keyup', onEnter);
  });
};

let loading = true;
const customPrint = (text, end = '\n') => {
  if (loading) {
    loading = false;
    const loadingEl = document.getElementById('loading');
    loadingEl.style.display = 'none';
    const consoleEl = document.getElementById('console');
    consoleEl.style.display = 'flex';
    setTimeout(() => {
      consoleEl.style.bottom = '50%';
    }, 1);
  }
  const output = document.getElementById('console-output');
  output.textContent += `${text}${end}`;
  // Scroll to the bottom
  output.scrollTop = output.scrollHeight;
};

const customPrintSlow = async (text, end = '\n') => {
  for (const char of text) {
    customPrint(char, '');
    await new Promise(resolve => setTimeout(resolve, 10));
  }

  customPrint(end);
};

const customClear = () => {
  const output = document.getElementById('console-output');
  output.textContent = '';
};

// Add this function to the global scope so it can be called from Python
window.customInput = customInput;
window.customPrint = customPrint;
window.customPrintSlow = customPrintSlow;
window.customClear = customClear;

export { customInput, customPrint };
